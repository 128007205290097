import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import AdminDashboardComponent from "../components/AdminDashboardComponent";
import StaffDashboardComponent from "../components/StaffDashboardComponent";
import NonStaffDashboardComponent from "../components/NonStaffDashboardComponent";

function Dashboard() {
    const user = AuthService.getCurrentUser();

    return (
        <>
            <AdminDashLayout>
                {user.role === 1 && (
                    <AdminDashboardComponent/>
                )}

{user.role === 2 && (
                    <AdminDashboardComponent/>
                )}

                
            </AdminDashLayout>
        </>
    );
}

export default Dashboard;
