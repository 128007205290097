import React, {useEffect, useState} from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {Button, Grid, Breadcrumb} from "semantic-ui-react";
import {Link, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {FaMailBulk, FaMapPin, FaPhone} from "react-icons/fa";
import WorkerProjectCards from "../components/WorkerProjectCards";
import {fetchWorker} from "../apiCalls";
import {UserAvatar} from "../components/UserAvatar";
import MapsComponent from "../components/MapsComponent";

const ViewWorker = () => {
    const { id } = useParams();
    const [worker, setWorker] = useState('')
    const [workerGeo, setWorkerGeo] = useState(null)

    useEffect(() => {
        fetchWorker(id, localStorage.getItem('token')).then(res => {
            setWorker(res.data)
            setWorkerGeo((res.data.geolocation == null ? null : JSON.parse(res.data.geolocation)));
        });
    }, [id]);
    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Link to={"/dashboard/workers"}>
                            <Breadcrumb.Section link>Workers</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        
                        <Breadcrumb.Section>{worker.fullname}</Breadcrumb.Section>
                    </Breadcrumb>
                </div>

                <Grid>
                    <Grid.Column floated="left" width={7}>
                        <div style={{ paddingTop: 30, paddingRight: 20 }}>
                            <div style={{display: "inline-flex"}}>
                                <div>
                                    <UserAvatar worker={worker} size={70} />
                                </div>
                                <div style={{ paddingLeft: 20, marginTop: 10 }}>
                                    <span style={{ fontSize: 25, paddingBottom: 20 }}>{worker.fullname}</span>
                                    <br />
                                    <br />
                                    <span style={{ fontSize: 15 }}> <FaMailBulk color={"#044f66"} /> {worker.email}</span>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
            </Grid>
            </AdminDashLayout>
        </>
    );
};

export default ViewWorker;
