import React, {useEffect, useState} from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {Button, Form, Grid, Breadcrumb, Dropdown, Message} from "semantic-ui-react";
import {Link, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {FileMosaic, Dropzone} from "@files-ui/react";
import {roleOptions} from "../utils";
import {emailRegex, phoneRegex} from '../utils'
import sendApiRequest, {API_URL} from "../api";
import {fetchWorker, uploadFiles} from "../apiCalls";
import {UserAvatar} from "../components/UserAvatar";

const EditWorker = () => {
    const { id } = useParams();
    const [worker, setWorker] = useState('')
    const [role, setRole] = useState('')
    const [fullname, setfullname] = useState('')
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState('');
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const [files, setFiles] = React.useState([]);
    const [allocatedHolidayTime, setAllocatedHolidayTime] = useState(1)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
    };

    useEffect(() => {
        fetchWorker(id, localStorage.getItem('token')).then(response => {
            setWorker(response.data);
            setRole(response.data.role);
            setfullname(response.data.fullname);
            setEmail(response.data.email);
            setPhone(response.data.phone_number);
        })
        setLoading(false);
    }, [id]);

    const updateFiles = (incomingFiles) => {
        //do something with the files
        setFiles(incomingFiles);
        //even your own upload implementation
        // uploadFiles(incomingFiles)
    };
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const weeksDropdown = [];

    for (let i = 1; i <= 12; i++) {
        const weeksText = i === 1 ? "1 week" : `${i} weeks`;
        weeksDropdown.push({ key: i, value: i, text: weeksText });
    }

    const defaultDropdownOption = weeksDropdown.find(option => option.value === allocatedHolidayTime);

    const handleSubmit = () => {
        scrollToTop();
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            role: role,
            fullname: fullname,
            email: email,
            phone: phone,
        };

        if (password !== undefined && password !== "") {
            formData.password = password;
        }

        let newErrors;
        newErrors = {};

        if (formData.fullname !== "") {

        } else {
            newErrors.fullname = "Please enter the workers fullname name";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

       
        if (formData.email !== "") {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.email = "Please enter the workers email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        if (formData.phone !== "") {
            if (!phoneRegex.test(formData.phone)) {
                newErrors.phone = "Please a valid phone number";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.phone = "Please enter the workers phone number";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }


        // request
        (async () => {

            try {
                let photoUri = '';

                // Construct formData here, including the photoUri if it was set
                const apiData = {
                    ...formData
                };

                if (files.length > 0) {
                    // Upload files and set the photoUri
                    const uploadData = await uploadFiles(files);
                    photoUri = uploadData.data[0].urls.path;
                    apiData.staff_photo_uri = photoUri || '';
                }

                // Send the API request
                const postData = await sendApiRequest("post", "/workers/edit/" + id, apiData, localStorage.getItem('token'));

                if (postData !== null) {
                    // Handle API response here
                    if (postData.errors || postData.error) {
                        // Handle errors
                        setApiErrors(true);
                        setApiSuccess(false);
                        const errors = postData.errors || [postData.error];
                        const errorMsg = errors.map((e) => e.msg);
                        setApiErrorMessage(errorMsg);
                    } else {
                        // Handle success
                        //toast("Changes Saved Successfully")
                        setApiSuccess(true);
                        setApiSuccessMessage("Changes Saved Successfully");
                    }
                } else {
                    // Handle API request error
                    setApiErrorMessage(["Error occurred while submitting data to the API."]);
                    setApiErrors(true);
                    setApiSuccess(false);
                }
            } catch (error) {
                // Handle any unexpected errors
                console.error(error);
            } finally {
                // Ensure setLoading is set to false
                setLoading(false);
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (<>
        <AdminDashLayout>
            <div style={{paddingTop: 40}}>
                <Breadcrumb>
                    <Link to={"/dashboard"}>
                        <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right chevron"/>
                    <Link to={"/dashboard/workers"}>
                        <Breadcrumb.Section link>Workers</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right chevron"/>
                    <Link to={"/dashboard/workers"}>
                        <Breadcrumb.Section link>{fullname}</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right arrow"/>
                    <Breadcrumb.Section>Edit</Breadcrumb.Section>
                </Breadcrumb>
            </div>
            <div style={{paddingTop: 30, paddingRight: 20}}>
                <Grid>
                    <Grid.Column width={8} floated="left">
                        {apiErrors ? (<Message
                            error
                            header="Whoops!"
                            list={apiErrorMessage}
                        />) : null}

                        {apiSuccess ? (<Message
                            success
                            header="Success!"
                            content={apiSuccessMessage}
                        />) : null}
                        <Form loading={loading ? true : false}>
                            <label>Access Level (Role) <b
                                style={{color: 'red', paddingBottom: '10px'}}>*</b></label>
                            <Dropdown
                                placeholder="Select Role"
                                fluid
                                search
                                selection
                                options={roleOptions}
                                value={role}
                                required={true}
                                error={errors.role ? {content: errors.role} : null}
                                onChange={(e, {value}) => {
                                    errors.role = null;
                                    setRole(value);
                                }}
                            />
                            <br/>

                            <Form.Input
                                label={"Worker's Full Name"}
                                size="medium"
                                value={fullname}
                                required={true}
                                error={errors.fullname ? {content: errors.fullname} : null}
                                onChange={(e) => {
                                    errors.fullname = null;
                                    setfullname(e.target.value);
                                }}
                            />


                            <Form.Input
                                label={"Worker's Email Address"}
                                size="medium"
                                value={email}
                                required={true}
                                error={errors.email ? {content: errors.email} : null}
                                onChange={(e) => {
                                    errors.email = null;
                                    setEmail(e.target.value);
                                }}
                            />

                            <Form.Input
                                label={"Worker's Phone Number"}
                                size="medium"
                                value={phone}
                                required={true}
                                error={errors.phone ? {content: errors.phone} : null}
                                onChange={(e) => {
                                    errors.phone = null;
                                    setPhone(e.target.value);
                                }}
                            />

                            <Form.Input
                                label={"Update Password (Optional)"}
                                size="medium"
                                type={"password"}
                                required={false}
                                error={errors.password ? {content: errors.password} : null}
                                onChange={(e) => {
                                    errors.password = null;
                                    setPassword(e.target.value);
                                }}
                            />

                         
                            <label style={{paddingBottom: 20}}>Worker Profile Photo (Optional) </label>
                            <br /><br />
                            Current Avatar: &nbsp;
                            <UserAvatar worker={worker} />
                            <br /><br />
                            <Dropzone
                                accept={"image/*"}
                                onChange={updateFiles}
                                value={files}
                                maxFileSize={2 * 1024 * 1024}
                                maxFiles={1}
                            >
                                {files.map((file) => (<FileMosaic
                                    key={file.id}
                                    {...file}
                                    onDelete={removeFile}
                                    info
                                    preview={true}
                                />))}
                            </Dropzone>
                            <br/><br/>

                            <Button
                                size="midi"
                                color="primary"
                                content="Save Changes"
                                icon="send"
                                labelPosition="right"
                                onClick={handleSubmit}
                            />
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        </AdminDashLayout>
    </>);
};

export default EditWorker;
