import React, { useEffect, useState } from "react";
import DateTimeComponent from "../components/DateTime";
import AuthService from "../auth";
import NotificationIcon from "./NotificationBell";
import MessageBell from "./MessageBell";
import { UserAvatar } from "./UserAvatar";
import { Link } from "react-router-dom";
import { fetchWorker } from "../apiCalls";
import Notification from './Noti'

const TopNav = () => {

    const user = AuthService.getCurrentUser();

    const [notifs, setNotifs] = useState([])

    useEffect(() => {
        try {
            // requestForToken();

            // fetchWorker(user.id).then(user => {
            //     setNotifs((user == null ? null : JSON.parse(user.data.notifications)))
            // })
        } catch (e) {
            //
        }

    }, [user.id]);

    return (
        <>
            <Notification />
            <div id={"topNav"}>
                <div style={{ display: "flex" }}>
                    <div className={"top-nav-datetime-component"}>
                        <DateTimeComponent />
                    </div>
                    <div style={{ width: "65%" }}>
                        <div style={{ display: "flex", alignItems: "center", float: "right" }}>
                            &nbsp; &nbsp;
                            <div className={"hidden-mobile"} style={{ color: "#044f66", fontSize: 16, fontWeight: "bold" }}>
                                Welcome, {user.fullname}!{" "}
                                <UserAvatar worker={user} size={38} />
                                &nbsp; &nbsp;
                                <Link to={"/logout"}>
                                    <button
                                        className="primary"
                                        aria-label="Toggle Navigation"
                                    >
                                        Logout
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNav;
