import React from "react";
import {MdHome} from "react-icons/md";
import {Link, matchPath, useLocation} from "react-router-dom";

const NonStaffSideNav = () => {
    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({path: pathPattern}, location.pathname);

        return match !== null;
    };

    return (
        <>
            <li
                className={
                    isActiveRoute("/dashboard") ||
                    isActiveRoute("/dashboard/grp") ||
                    isActiveRoute("/dashboard/grp/view/:id") ||
                        isActiveRoute("/dashboard/grp/edit/:id")
                        ? "active"
                        : ""
                }>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                GRP Housing
              </span>
                </Link>
            </li>
        </>
    );
};

export default NonStaffSideNav;
