import React, { useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid, Breadcrumb, Message } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { emailRegex } from '../utils'
import sendApiRequest from "../api";
import { fetchAccount, fetchAccounts, uploadFiles } from "../apiCalls";
import { useEffect } from "react";

const EditAccount = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const [files, setFiles] = React.useState([]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
    };

    const { id } = useParams()

    useEffect(() => {
        fetchAccount(id, localStorage.getItem('token')).then(response => {
            setEmail(response?.email);
            setPassword(response?.app_password)
        })
        setLoading(false);
    }, []);

    const updateFiles = (incomingFiles) => {
        setFiles(incomingFiles);
    };

    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const handleSubmit = async () => {
        scrollToTop();
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            id: id,
            email: email,
            app_password: password,
        };

        let newErrors = {};

        if (formData.email !== "") {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.email = "Please enter the email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        try {
            let photoUri = '';

            if (files.length > 0) {
                const uploadData = await uploadFiles(files);
                photoUri = uploadData.data[0].urls.path;
            }

            const apiData = {
                ...formData,
            };

            if (photoUri !== '') {
                apiData.staff_photo_uri = photoUri;
            }

            const postData = await sendApiRequest("post", "/dashboard/manage-accounts/edit", apiData, localStorage.getItem('token'));

            if (postData !== null) {
                setLoading(false);

                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    const errors = postData.errors || [postData.error];
                    const errorMsg = errors.map((e) => e.msg);
                    setApiErrorMessage(errorMsg);
                } else if (postData.message === "success") {
                    setApiSuccess(true);
                    setApiSuccessMessage("Account Updated Successfully");
                
                    setFiles([]);
                }
            } else {
                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                setApiErrors(true);
                setApiSuccess(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Link to={"/dashboard/manage-accounts"}>
                            <Breadcrumb.Section link>Email Accounts</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Breadcrumb.Section>Edit Account</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <div style={{ paddingTop: 30, paddingRight: 20 }}>
                    <Grid>
                        <Grid.Column width={18} floated="left">
                            {apiErrors ? (
                                <Message
                                    error
                                    header="Whoops!"
                                    list={apiErrorMessage}
                                />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                            <Form loading={loading}>
                                <Form.Input
                                    label={"Email Address"}
                                    size="medium"
                                    value={email}
                                    required={true}
                                    error={errors.email ? { content: errors.email } : null}
                                    onChange={(e) => {
                                        errors.email = null;
                                        setEmail(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    label={"App Password"}
                                    size="medium"
                                    type={"text"}
                                    required={true}
                                    error={errors.password ? { content: errors.password } : null}
                                    onChange={(e) => {
                                        errors.password = null;
                                        setPassword(e.target.value);
                                    }}
                                    value={password}
                                />

                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Submit Request"
                                    icon="send"
                                    labelPosition="right"
                                    onClick={handleSubmit}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </AdminDashLayout>
        </>
    );
};

export default EditAccount;

