import React from "react";
import {MdEmail, MdHome} from "react-icons/md";
import {Link, useLocation, matchPath} from "react-router-dom";
import {TiSpannerOutline} from "react-icons/ti";
import {MdOutlineAccessTimeFilled} from "react-icons/md";
import {SlCalender} from "react-icons/sl";
import {AiOutlineMessage} from "react-icons/ai";
import {IoMdDocument} from "react-icons/io";
import {FaLink} from "react-icons/fa";
import Auth from '../auth'

const StaffSideNav = () => {
    const user = Auth.getCurrentUser()
    const userId = user.id;

    const location = useLocation();

    const isActiveRoute = (pathPattern) => {
        const match = matchPath({path: pathPattern}, location.pathname);

        return match !== null;
    };

    return (
        <>
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Dashboard
              </span>
                </Link>
            </li>
            <br />
            <li
                className={
                        isActiveRoute("/dashboard/manage-accounts") ||
                        isActiveRoute("/dashboard/manage-accounts/create") ||
                        isActiveRoute("/dashboard/manage-accounts/edit/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/manage-accounts"}>
                    <MdEmail size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Email Accounts
                    </span>
                </Link>
            </li>

        </>
    );
};

export default StaffSideNav;
