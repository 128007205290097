import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-less/semantic.less";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "./fonts/Geist-1.3.0/statics-ttf/Geist-Medium.ttf"
import "./fonts/GreycliffCF-Bold.woff2";
import "./fonts/GreycliffCF-Light.woff2";
import "./fonts/GreycliffCF-Medium.woff2";
import "./fonts/GreycliffCF-Regular.woff2";
import "./fonts/Larsseit-Bold.woff2";
import "./fonts/Larsseit-Light.woff2";
import "./fonts/Larsseit-Medium.woff2";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import AuthService from "./auth";
import Logout from "./components/Logout";
import Inactive from "./pages/Inactive";
import Workers from "./pages/Workers";
import AddWorker from "./pages/AddWorker";
import ViewWorker from "./pages/ViewWorker";
import EditWorker from "./pages/EditWorker";
import ErrorBoundary from "./components/ErrorBoundary";

import GetOtp from "./pages/GetOtp";
import ManageAccounts from "./pages/ManageAccounts";
import CreateAccount from "./pages/CreateAccount";
import EditAccount from "./pages/EditAccount";

// Middleware component to check if the user is authenticated
const AuthenticatedMiddleware = ({ element: Element }) => {
  const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic

  if (isAuthenticated) {
    return <Element />;
  } else {
    // Redirect
    return <Navigate to="/logout" />;
  }
};

const AuthenticatedRoleMiddleware = ({ element: Element }) => {
  const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic
  const user = AuthService.getCurrentUser();

  if (isAuthenticated && user.role === 1) {
    return <Element />;
  } else {
    // Redirect
    return <Navigate to="/logout" />;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <GetOtp />,
  },
  {
    path: "/login",
    element: <App />,
  },
  {
    path: "/inactive",
    element: <Inactive />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/dashboard/*",
    element: (
      <ErrorBoundary>
        <Routes>
          <Route
            path="/"
            element={<AuthenticatedMiddleware element={Dashboard} />}
          />

          <Route
            path="/manage-accounts"
            element={<AuthenticatedMiddleware element={ManageAccounts} />}
          />

          <Route
            path="/manage-accounts/create"
            element={<AuthenticatedMiddleware element={CreateAccount} />}
          />

          <Route
            path="/manage-accounts/edit/:id"
            element={<AuthenticatedMiddleware element={EditAccount} />}
          />

          <Route
            path="/workers"
            element={<AuthenticatedRoleMiddleware element={Workers} />}
          />
          <Route
            path={"/workers/create"}
            element={<AuthenticatedRoleMiddleware element={AddWorker} />}
          />
          <Route
            path={"/workers/view/:id"}
            element={<AuthenticatedRoleMiddleware element={ViewWorker} />}
          />
          <Route
            path={"/workers/edit/:id"}
            element={<AuthenticatedRoleMiddleware element={EditWorker} />}
          />

        </Routes>
      </ErrorBoundary>
    ),
  },
  {
    path: "/logout",
    element: <Logout />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
