import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Divider, Form, Message} from "semantic-ui-react";
import {emailRegex} from "../utils";
import sendApiRequest from "../api";
import {useNavigate} from "react-router-dom";
import AuthPageLayout from "../components/AuthPageLayout";
import logo from '../assets/logo.avif'

function GetOtp() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [streamingService, setService] = useState("") || "netflix";
    const [serv, setServ] = useState('')
    const [isLink, setIsLink] = useState(false)
    const [link, setLink] = useState('')
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);
        setIsLink(false)

        const formData = {
            email: email,
            service: serv,
        };

        const newErrors = {};

        if (formData.email === "") {
            newErrors.email = "Please enter your email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        } else {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        }

        if (formData.service === "") {
            newErrors.service = "Please select a streaming service";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        } else {
            
        }

        // Login request
        (async () => {

            const postData = await sendApiRequest("post", "/request_otp", formData, { timeout: 30000 });

            console.log('d', postData)

            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    setLink(false)
                    const errors = postData.errors;
                    const errorMsg = [];

                    if (postData.errors) {
                        errors.forEach((e) => {
                            errorMsg.push(e.msg);
                        });
                    } else {
                        errorMsg.push(postData.error);
                    }

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.message === 'success') {
                    setApiSuccess(true);
                    setLoading(false);

                    if(postData.codes !== null && postData.codes.length && postData.codes.length > 0) {
                        setApiSuccessMessage(
                            `OTP Code is: ${postData.codes[0]}`
                        );
                    }
                    
                    if(postData.links !== null && postData.links.length && postData.links.length > 0) {
                        setApiSuccessMessage(
                            `Your ${serv} access code can be accessed via the button link below.`
                        );
                        setLink(postData.links[0])
                        setIsLink(true)
                    }

                   
                   
                } else {
                    setApiErrors(true);
                    setApiSuccess(false);
                    setApiErrorMessage(['No OTP Code / Link found'])
                }
            } else {

                setApiErrorMessage(["No OTP Code / Link found."]);
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);

                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    };

    return (
        <AuthPageLayout>
            <div className={"auth-page"}>
                <div className="auth-box">
                    <center><img src={logo} style={{textAlign:'center', margin:'0px auto 0px'}} width={100} /></center>
                    <h3 className="text-black text-center" style={{textAlign:'center'}}>Request For OTP</h3>
                    {apiErrors ? (
                        <Message
                            error
                            header="Whoops!"
                            list={apiErrorMessage}
                        />
                    ) : null}
                    ;
                    {apiSuccess ? (
                        <Message
                            success
                            header="Success!"
                            content={apiSuccessMessage}
                        />
                    ) : null}

                    {isLink ? (
                        <>
                        <Button primary fluid onClick={() => {
                            window.open(link, '_blank');
                        }}>
                            Get Code
                        </Button>
                        <br />
                        </>
                    ) : null}

                    <Form loading={loading ? true : false}>
                        <Form.Input
                            name="email"
                            label="Email address"
                            icon="mail"
                            iconPosition="left"
                            placeholder="Enter your email"
                            value={email}
                            required={true}
                            error={errors.email ? {content: errors.email} : null}
                            onChange={(e) => {
                                errors.email = null;
                                setEmail(e.target.value);
                            }}
                        />

                        <Form.Select
                            fluid
                            label="Select Service"
                            options={[
                                { key: 'netflix', value: 'netflix', text: 'Netflix' },
                                { key: 'disney', value: 'disney', text: 'Disney+' },
                                { key: 'hulu', value: 'hulu', text: 'Hulu' },
                                { key: 'amazon', value: 'amazon', text: 'Amazon Prime Video' },
                                { key: 'hbo', value: 'hbo', text: 'HBO Max' },
                                { key: 'espn', value: 'espn', text: 'ESPN+' },
                            ]}
                            placeholder="Select Service"
                            onChange={(e, {value}) => {
                                setServ(value);
                            }}
                        />

                        <Button blue fluid onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                    
                </div>
            </div>
        </AuthPageLayout>
    );
}

export default GetOtp;
