import React from "react";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";

const DashCards = ({
  totalProjectsCount,
  role = 1
}) => (
  <>
    {role === 1 && (
      <>
        <Card.Group stackable itemsPerRow={1}>
          <Card loading style={{ backgroundColor: "#b3e5fc", padding: 10 }}>
            <Link to={'/dashboard/manage-accounts'} style={{ padding: "1em 1em" }}>
            <Card.Content>
              <Card.Header style={{ fontSize: "1.3em" }}>TOTAL ACCOUNTS</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalProjectsCount}
                </h1>
              </Card.Description>
            </Card.Content>
            </Link>
          </Card>
         
        </Card.Group>
      </>
    )}

    
  </>
);

export default DashCards;
