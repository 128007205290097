import React, { useState } from "react";
import { Icon, Input, Table, Button, Grid, Modal, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAccount, deleteWorker } from "../apiCalls";
import Avatar from "react-avatar";
import { FaMapPin } from "react-icons/fa";
import { WorkerRoleName } from "./WorkerRoleName";
import AuthService from "../auth";

function WorkersTable({ count, workers, onPrevPageChange, onNextPageChange, onDeleteWorker }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModals, setOpenModals] = useState({}); // Separate state for modals

  const user = AuthService.getCurrentUser();

  const handleDeleteModalOpen = (clientId) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [clientId]: true,
    }));
  };

  const handleDeleteModalClose = (clientId) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [clientId]: false,
    }));
  };

  const filteredWorkers = workers.filter((worker) =>
    [
      worker.email
    ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <>
      <Grid>

        <Grid.Column width={12}>
          <div style={{ paddingTop: 0, display: 'flex' }}>

            <Link to={"/dashboard/manage-accounts/create"}>
              <Button
                color="primary"
                content="ADD A NEW ACCOUNT"
                icon="plus"
                labelPosition="right"
              />
            </Link>
          </div>
        </Grid.Column>
        <Grid.Column width={12} >
          <div style={{ paddingBottom: 15, marginLeft: "0px" }}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Search Accounts"
              className=""
              size="midi"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div style={{ paddingBottom: 15 }}>
            <span style={{ fontSize: 17 }}>Displaying {count} result(s)</span>
          </div>
        </Grid.Column>
      </Grid>
      <Table basic="very" padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S/N</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>App Password</Table.HeaderCell>

            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredWorkers.map((worker, index) => (
            <Table.Row key={index}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                {worker.email}
              </Table.Cell>
              <Table.Cell>
                {worker.app_password}
              </Table.Cell>


              <Table.Cell>
                {user.role === 1 && (
                  <>
                  <Link style={{ color: '#000' }} to={"/dashboard/manage-accounts/edit/" + worker.id}>
                  <Button as="a" compact color="primary">
                    Edit
                  </Button>{" "}
                </Link>

                <>
                  /{" "}
                  <Modal
                    dimmer={"blurring"}
                    basic={true}
                    onClose={() => handleDeleteModalClose(worker.id)}
                    onOpen={() => handleDeleteModalOpen(worker.id)}
                    open={openModals[worker.id] || false} // Use separate state for each modal
                    size="small"
                    trigger={
                      <Button compact as="a" color="red">
                        Delete
                      </Button>
                    }
                  >
                    <Header icon>
                      <Icon name='archive' />
                      Delete Account: {worker.fullname}?
                    </Header>
                    <Modal.Content>
                      <p style={{ textAlign: "center" }}>
                        Are you sure?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button inverted={true} color="red" onClick={() => handleDeleteModalClose(worker.id)}>
                        <Icon name="remove" /> No
                      </Button>
                      <Button inverted={true} color="green" onClick={async () => {
                        handleDeleteModalClose(worker.id);
                        toast('Account Successfully Deleted', {
                          type: "success"
                        });

                        onDeleteWorker(worker.id);

                        await deleteAccount(worker.id, localStorage.getItem('token'));
                      }}>
                        <Icon name="checkmark" /> Yes
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </>
                  </>
                )}
                

              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                Previous Page
                <Icon name="left arrow" />
              </Button>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="7">
              <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                Next Page
                <Icon name="right arrow" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
}

export default WorkersTable;