import React from "react";
import { MdAccountCircle, MdEmail, MdHome } from "react-icons/md";
import { Link, useLocation, matchPath } from "react-router-dom";
import Auth from '../auth';

const AdminSidenav = () => {
    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({ path: pathPattern }, location.pathname);

        return match !== null;
    };

    const user = Auth.getCurrentUser()
    const userId = user.id;
    const useeRole = user.role;

    return (
        <>
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Dashboard
                    </span>
                </Link>
            </li>
            <br />
            <li
                className={
                        isActiveRoute("/dashboard/manage-accounts") ||
                        isActiveRoute("/dashboard/manage-accounts/create") ||
                        isActiveRoute("/dashboard/manage-accounts/edit/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/manage-accounts"}>
                    <MdEmail size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Email Accounts
                    </span>
                </Link>
            </li>
            <br />
  
            <li
                className={
                    isActiveRoute("/dashboard/workers") ||
                    isActiveRoute("/dashboard/workers/view/:id") ||
                        isActiveRoute("/dashboard/workers/create") ||
                        isActiveRoute("/dashboard/workers/edit/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/workers"}>
                    <MdAccountCircle size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Staff Accounts
                    </span>
                </Link>
            </li>

        </>
    );
};

export default AdminSidenav;
