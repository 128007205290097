import DashCards from "../components/DashCards";
import RecentProjects from "../components/RecentProjects";
import {useEffect, useState} from "react";
import {fetchDashboardStats} from '../apiCalls'
function AdminDashboardComponent() {
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalHoursLogged, setTotalHoursLogged] = useState(0);
    const [totalCompletedProjectsCount, setTotalCompletedProjectsCount] = useState(0);
    const [projects, setProjects] = useState();

    useEffect(() => {
        fetchDashboardStats(localStorage.getItem('token')).then(data => {
            setTotalCompletedProjectsCount(data.totalEmailAccounts);
        });
    }, []);

    return (
        <>
            <div style={{paddingTop: 30, paddingRight: 10}}>
                <h3>Dashboard</h3>
                <br />
                <DashCards totalProjectsCount={totalCompletedProjectsCount} role={1} />
        
         
            </div>
        </>
    );
}

export default AdminDashboardComponent;
