import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            // Customize the error message or component here
            return (
                <div style={{background: "#3847d2", height: "100vh", padding: "20px", textAlign: "center"}}>
                    <img src={require('../assets/logo.avif')} alt="" style={{width: "100px", borderRadius: "100px"}} />
                    <h1>Something went wrong.</h1>
                    <p>We apologize for the inconvenience. Please refresh or try again later.</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
