import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Divider, Form, Message} from "semantic-ui-react";
import {emailRegex} from "../utils";
import sendApiRequest from "../api";
import {useNavigate} from "react-router-dom";
import AuthPageLayout from "../components/AuthPageLayout";
import logo from '../assets/logo.avif'

function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            email: email,
            password: password,
        };

        const newErrors = {};

        if (formData.email === "") {
            newErrors.email = "Please enter your email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        } else {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        }

        if (formData.password === "") {
            newErrors.password = "Please enter your password";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        } else {
            if (formData.password.length < 6) {
                newErrors.password = "Password must be at least 6 characters long";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", "/auth/login", formData);
            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    const errors = postData.errors;
                    const errorMsg = [];

                    if (postData.errors) {
                        errors.forEach((e) => {
                            errorMsg.push(e.msg);
                        });
                    } else {
                        errorMsg.push(postData.error);
                    }

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.token) {

                    setApiSuccess(true);
                    setApiSuccessMessage(
                        "You are now signed in. Redirecting in 1 second.."
                    );

                    const token = postData.token;
                    const user = postData.user;
                    const expires = postData.expires;

                    localStorage.setItem("token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("loggedin", true);
                    localStorage.setItem("expires", expires);

                    return navigate("/dashboard");
                }
            } else {

                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);

                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <AuthPageLayout>
            <div className={"auth-page"}>
                <div className="auth-box">
                <center><img src={logo} style={{textAlign:'center', margin:'0px auto 0px'}} width={100} /></center>
                    {apiErrors ? (
                        <Message
                            error
                            header="Whoops!"
                            list={apiErrorMessage}
                        />
                    ) : null}
                    ;
                    {apiSuccess ? (
                        <Message
                            success
                            header="Authentication complete"
                            content={apiSuccessMessage}
                        />
                    ) : null}
                    <Form loading={loading ? true : false}>
                        <Form.Input
                            name="email"
                            label="Email address"
                            icon="mail"
                            iconPosition="left"
                            placeholder="Enter your email"
                            value={email}
                            required={true}
                            error={errors.email ? {content: errors.email} : null}
                            onChange={(e) => {
                                errors.email = null;
                                setEmail(e.target.value);
                            }}
                        />

                        <Form.Input
                            type="password"
                            name="password"
                            label="Password"
                            icon="lock"
                            iconPosition="left"
                            placeholder="Enter your password"
                            required={true}
                            error={errors.password ? {content: errors.password} : null}
                            onChange={(e) => {
                                errors.password = null;
                                setPassword(e.target.value);
                            }}
                        />

                        <Button blue fluid onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                   
                </div>
            </div>
        </AuthPageLayout>
    );
}

export default Login;
