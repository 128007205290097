import sendApiRequest, {API_URL} from "./api";

const fetchDashboardStats = async (token) => {
    try {
        const getResponse = await sendApiRequest("get", "/dashboard", null, token);
        if (getResponse.errors || getResponse.error) {
            return null;
        }

        return getResponse.data;
    } catch (err) {
        throw err;
    }
};

const fetchClients = async (pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/clients?pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchWorker = async (id, token) => {
    const getResponse = await sendApiRequest("get", "/workers/fetch?id=" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

export const fetchAccount = async (id, token) => {
    const getResponse = await sendApiRequest("get", "/dashboard/manage-accounts/fetch?id=" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}


const fetchWorkers = async (pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/workers?pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchAccounts = async (pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/dashboard/manage-accounts?pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchProjects = async (pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/projects?pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchProjectsByClient = async (id, pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/clients/projects?id=" + id + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const approveProjectBooking = async (id) => {
    const getResponse = await sendApiRequest("post", "/projects/booking/approve/" + id, null);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const rescheduleProjectBooking = async (id, date, time) => {
    const getResponse = await sendApiRequest("post", "/projects/booking/reschedule/" + id, { date, time });
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const cancelProjectBooking = async (id) => {
    const getResponse = await sendApiRequest("post", "/projects/booking/cancel/" + id, null);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchCalEvents = async (pageNumber = 1, pageSize = 20, token) => {
    const getResponse = await sendApiRequest("get", "/calevents?pageNumber=" + pageNumber + "&pageSize=" + pageSize, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchProject = async (id, token) => {
    const getResponse = await sendApiRequest("get", "/projects/fetch?id=" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const fetchClient = async (id, token) => {
    const getResponse = await sendApiRequest("get", "/clients/fetch/?id=" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const deleteClient = async (id, token) => {
    const getResponse = await sendApiRequest("post", "/clients/delete/" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const deleteWorker = async (id, token) => {
    const getResponse = await sendApiRequest("post", "/workers/delete/" + id, null, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

export const deleteAccount = async (id, token) => {
    const getResponse = await sendApiRequest("post", "/dashboard/manage-accounts/delete", {id: id}, token);
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const updateImages = async (id, data) => {
    const getResponse = await sendApiRequest("post", "/projects/edit/" + id, {projectImages: data}, localStorage.getItem('token'));
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}


const updateGrpImages = async (id, data, column) => {
    const getResponse = await sendApiRequest("post", "/grp/edit/" + id, {column: data}, localStorage.getItem('token'));
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const updateSchematics = async (id, data) => {
    const getResponse = await sendApiRequest("post", "/projects/edit/" + id, {projectSchematics: data}, localStorage.getItem('token'));
    if (getResponse.errors || getResponse.error) {
        return false;
    }

    return true;
}

const updateInvoices = async (id, data) => {
    const getResponse = await sendApiRequest("post", "/projects/edit/" + id, {projectInvoices: data}, localStorage.getItem('token'));
    if (getResponse.errors || getResponse.error) {
        return null;
    }

    return getResponse;
}

const uploadFiles = (files) => {
    // Prepare files to send to the server with the name "files[]"
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files[]', file.file);
    });

    // Add other necessary headers and options to your request
    return fetch(API_URL + "/upload", {
        method: "POST",
        headers: {
            Authorization: localStorage.getItem('token'),
        },
        body: formData,
    })
        .then((response) => {
            console.log("upr", response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            // Handle the server response here
            return data;
        })
        .catch((error) => {
            // Handle errors here
            throw error; // Re-throw the error to propagate it
        });
};


export {
    fetchDashboardStats,
    fetchClients,
    fetchClient,
    deleteClient,
    uploadFiles,
    fetchWorkers,
    fetchWorker,
    deleteWorker,
    fetchProjects,
    fetchProject,
    updateImages,
    updateSchematics,
    updateInvoices,
    updateGrpImages,
    fetchCalEvents,
    fetchProjectsByClient,
    approveProjectBooking,
    rescheduleProjectBooking,
    cancelProjectBooking,
    fetchAccounts
}; // Use export to export the function
